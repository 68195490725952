@import "./breakpoint.scss";

.body {
  display: grid;
  justify-content: center;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.body::-webkit-scrollbar {
  display: none;
}

.pochette {
  place-self: center;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 20px;

  .pochette-controls-container {
    display: grid;
    justify-self: end;
    justify-items: center;
    .pochette-image-container {
      position: relative;

      .pochette-image {
        justify-self: self-end;
        max-width: 260px;
        max-height: 260px;
        box-shadow: 0px 0px 50px #000000bf;
      }

      .black-filter {
        position: absolute;
        width: 100%;
        height: 260px;
        background: #10101b;
        opacity: 0;
        top: 0;
        left: 0;

        &.displayed {
          opacity: 0.3;
        }
      }

      .play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        border: 0;
        background: transparent;
        box-sizing: border-box;
        width: 0;
        border-color: transparent transparent transparent white;
        opacity: 1;
        cursor: pointer;
        // play state
        border-style: solid;
        border-width: 30px 0 30px 48px;
        height: 60px;

        &.displayPause {
          border-style: double;
          border-width: 0px 0 0px 48px;
          opacity: 0;
        }
      }
    }

    .slider {
      width: fit-content;
      display: grid;
      place-content: center;
      place-items: center;
      height: 60px;
      gap: 10px;
      opacity: 0;
    }
  }

  .pochette-controls-container:hover {
    .pochette-image-container {
      .black-filter {
        opacity: 0.3;
        transition: 500ms opacity ease;
      }
      .play-button {
        opacity: 1;
        transition: 500ms opacity ease;
        transition: 100ms border-style ease;
        transition: 100ms border-width ease;
      }
    }

    .slider {
      opacity: 1;
      transition: 500ms opacity ease;
    }
  }

  .pochette-controls-container:not(:hover) {
    .pochette-image-container {
      .black-filter {
        transition-delay: 5s;
        opacity: 0;
      }
      .play-button {
        opacity: 0;
        transition-delay: 5s;
      }
    }

    .slider {
      opacity: 0;
      transition-delay: 5s;
    }
  }

  .description {
    max-width: 500px;
    max-height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    .direct {
      display: grid;
      width: 76px;
      height: 26px;
      background: #c0392b 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 1;
      margin-bottom: 16px;

      span {
        place-self: center;
        text-align: left;
        font: normal normal 800 10px/13px Montserrat;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
    }

    .artist {
      display: grid;
      align-content: center;
      height: fit-content;
      text-overflow: ellipsis;
      text-align: left;
      font: normal normal bold 35px/30px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    .title {
      display: grid;
      height: fit-content;
      text-overflow: ellipsis;
      text-align: left;
      font: normal normal 300 25px/30px Montserrat;
      letter-spacing: 0px;
      color: #ffffff;
      text-transform: uppercase;
    }
  }

  .historic {
    height: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;

    .music {
      display: grid;
      grid-template-columns: auto auto 1fr;
      gap: 10px;
      align-items: center;
      width: 100%;

      .starttime {
        display: grid;
        place-content: center;
        justify-self: end;
        width: 56px;
        height: 27px;
        border: 1px solid #ffffff;
        color: #ffffff;
        font-weight: 500;
        font-size: 12px;
        font-family: Montserrat;
        border-radius: 4px;
        opacity: 1;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      img {
        justify-self: center;
        width: 80px;
        height: 80px;
        opacity: 1;
      }

      .historic-description {
        display: flex;
        flex-direction: column;
        .artist {
          height: 25px;
          text-align: left;
          font: normal normal bold 15px/13px Montserrat;
          letter-spacing: 0px;
          color: #ffffff;
          text-transform: uppercase;
          text-overflow: ellipsis;
        }
        .title {
          height: 20px;
          text-align: left;
          font: normal normal normal 10px/13px Montserrat;
          letter-spacing: 0px;
          color: #ffffff;
          text-transform: uppercase;
          text-overflow: ellipsis;
        }
      }
    }
  }

  @media (max-width: $breakpoint-laptop) {
    grid-template-columns: 1fr;
    align-self: start;
    .pochette-controls-container {
      justify-self: center;
    }

    .pochette-image,
    .description {
      justify-self: center;
    }

    .description {
      align-items: center;
      .artist,
      .title {
        justify-content: center;
      }
    }

    .historic {
      grid-column-start: 1;
      grid-column-end: 1;
    }
  }

  @media (max-width: $breakpoint-phone) {
    /*
    .description {
      display: grid;
      .artist {
        grid-area: 1/1/2/3;
      }
      .direct {
        grid-area: 2/1/3/2;
      }
      .title {
        grid-area: 2/2/3/3;
      }
    }*/
  }
}
