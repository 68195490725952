.dedicace {
  justify-self: center;
  display: grid;
  width: 210px;
  height: 40px;
  border: 2px solid #ffffff;
  border-radius: 4px;
  opacity: 1;
  margin-top: 25px;
  transition: 0.5s;
  
  div {
    place-self: center;
    text-align: left;
    text-transform: uppercase;
    font: normal normal 800 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    background-color: transparent;
  }
}

.dedicace:hover {
  background-color: #ffffff;
  cursor: pointer;
  div {
    color: black;
  }
}
