.container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
  gap: 10px;
  height: 100%;
  width: 100%;
  opacity: 1;
  .header {
    display: grid;
  }
}

.loading-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background: #10101b 0% 0% no-repeat padding-box;
  opacity: 1;
}

.error-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background: #4d2e2e 0% 0% no-repeat padding-box;
  opacity: 1;
}
