.radio-info {
  margin-top: 10px;
  justify-self: center;
  height: auto;
  width: 80%;
  border-bottom: 2px solid #ffffff;

  display: flex;
  flex-direction: column;

  .radio-name {
    align-self: center;
    width: 85%;
    height: 100px;

    background: url("../images/logo_FunAdos.png");

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
