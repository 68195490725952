.home {
  grid-area: 2/ 1 / 3 / 1;
  display: grid;
  place-items: center;

  cursor: pointer;
  .headset {
    align-self: end;
    width: 197px;
    height: 177px;
    color: white;
    opacity: 1;
    margin-bottom: 20px;
  }

  .pulse-animation {
    animation-name: pulse;
    -webkit-animation-name: pulse;
    animation-duration: 2s;
    -webkit-animation-duration: 2s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
  }

  .label {
    align-self: start;
    text-align: center;
    font: normal normal normal 22px/27px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
}

.home-background {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background: #10101b 0% 0% no-repeat padding-box;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.95);
  }
}
