.background-container {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    transform: scale(1.1);
    filter: blur(10px);
    -webkit-filter: blur(10px);

    .black-filter {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #10101b;
      opacity: 0.25;
    }

    .gradient-filter {
      position: absolute;
      width: 100%;
      height: 100%;
      background: transparent
        linear-gradient(180deg, #000000 0%, #54545400 100%) 0% 0% no-repeat
        padding-box;
      opacity: 0.75;
    }
  }
}

